<script>
import PollBlockNew from './PollBlockNewView'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'PollBlockEditView',
  extends: PollBlockNew,
  methods: {
    getPollBlock () {
      this.$store.dispatch('pollBlock/fetchOne', this.$route.params.id)
        .then(() => {
          this.pollBlock = this.$store.getters['pollBlock/detail']
          this.oldIdentifier = this.pollBlock.identifier
        })
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid || !this.isIdentifierValid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      this.$store.dispatch('pollBlock/update', this.pollBlock)
        .then(() => {
          if (this.$store.getters['pollBlock/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
          } else {
            NotifyService.setErrorMessage(this.$store.getters['pollBlock/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  created () {
    this.getPollBlock()
  }
}
</script>
